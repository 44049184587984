<template>
	<div class="salesOverviewbox">
		<div class="filter-container" style="padding-top: 15px;">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-select class="key-options" v-model="keyOptionsValue">
					<!-- <el-option label="全部" :value="null"></el-option> -->
					<el-option v-for="item in keyOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
				<el-input v-model="searchKey" style="margin-left:5px;width:300px;" placeholder="姓名、微信昵称、手机号" clearable></el-input>
			</div>
			<div>
				<div class="filter-item">
					<label class="label">时间:</label>
					<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
					 format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
					<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
					 format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<div class="filter-item">
					<label class="label">推手级别:</label>
					<el-select v-model="grade">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in gradeOptions" :key="item.Id" :label="item.DistributRoleName" :value="item.Id"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">订单来源:</label>
					<el-select v-model="OrderFrom">
						<el-option v-for="item in OrderFromlist" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
					<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
				</div>
			</div>

		</div>
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading="loading" @sort-change="sortChange">
				<el-table-column key="1" label="推手" width="200px">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<img :src="scope.row.WxHeadUrl ? (scope.row.WxHeadUrl) : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;">
							<div style="margin-left: 10px">
								<div class="oneFlows">{{scope.row.Name ? scope.row.Name : (scope.row.WxNickname ? scope.row.WxNickname : '')}}</div>
								<div>{{scope.row.Phone}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column key="2" prop="DistributRoleName" label="推手级别"></el-table-column>

				<el-table-column key="3" label="推荐人" width="200px">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<img v-if="scope.row.RecommendWxHeadUrl" :src="scope.row.RecommendWxHeadUrl?(scope.row.RecommendWxHeadUrl):defaultHeader"
							 style="width: 50px;height: 50px;border-radius: 100%;">
							<div style="margin-left: 10px">
								<div class="oneFlows">{{scope.row.RecommendName ? scope.row.RecommendName : (scope.row.RecommendWxNickname ? scope.row.RecommendWxNickname:'')}}</div>
								<div>{{scope.row.RecommendPhone}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column key="4" label="自营销售" prop="SellMoney" sortable>
					<template slot-scope='scope'>
						<div v-if="scope.row.SellMoney >0" style="color: #409EFF;min-width: 30px;min-height: 20px; cursor:pointer" @click="showlockfanList(scope.row,0)">{{scope.row.SellMoney}}</div>
						<div v-else>{{scope.row.SellMoney}}</div>
					</template>
				</el-table-column>
				<el-table-column key="5" label="自营退款" sortable prop="RealRefundMoney">
					<template slot-scope='scope'>
						<div v-if="scope.row.RealRefundMoney >0" style="color: #409EFF;min-width: 30px;min-height: 20px; cursor:pointer"
						 @click="showlockfanList(scope.row,1)">{{scope.row.RealRefundMoney}}</div>
						<div v-else>{{scope.row.RealRefundMoney}}</div>
					</template>
				</el-table-column>

				<el-table-column key="6" label="直推销售" sortable prop="RecommendSellMoney">
					<template slot-scope='scope'>
						<div>{{scope.row.RecommendSellMoney}}</div>
					</template>
				</el-table-column>

				<el-table-column key="7" label="直推退款" sortable prop="RecommendRealRefundMoney">
					<template slot-scope='scope'>
						<div>{{scope.row.RecommendRealRefundMoney}}</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;padding-bottom: 20px;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total"></el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import config from '@/config/index';
	import {
		memberDistributorSellInfo
	} from '@/api/TurnTomySelf.js'
	import {
		distfilterBoxList,

	} from '@/api/wyUsedInterface.js'
	export default {
		data() {
			return {
				searchKey: '',
				starTime: '',
				endTime: '',
				grade: null,
				gradeOptions: [],
				OrderFrom:0,
				OrderFromlist:[
					{
						value:0,
						label:'小程序商城订单'
					},{
						value:1,
						label:'视频号小店订单'
					},
				],
				loading: false,
				exportUrl: config.EXPORT_URL,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				OrderByTpye: '',
				IsAsc: 0,
				tableData: [],
				defaultHeader: config.DEFAULT_HEADER,
				duringdays: null,

				keyOptionsValue: 0,
				keyOptions: [{
						label: '推手信息',
						value: 0
					},
					{
						label: '推荐人信息',
						value: 1
					}
				],
			}
		},
		beforeMount() {
			//this.getRecentDays(31)
			this.initTime();
			this.getAuthList()
			this.getList()
		},
		methods: {
			initTime(){
			    let date = new Date();
			    let year = date.getFullYear();
			    let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
			    let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
			    this.starTime = year + '-' + month + '-' + '01' + ' ' + '00:00:00';
			    this.endTime = year + '-' + month + '-' + day + ' ' + '23:59:59';
			},
			//推手级别
			async getAuthList() {
				try {

					let result = await distfilterBoxList()
					this.gradeOptions = result.Result

				} catch (e) {
				}
			},
			showlockfanList(row,index) {
				this.$router.push({
					path: '/distribution/referrer/salesDetail',
					query: {
						KeyWords: row.Phone ? row.Phone : '',
						starTime:this.starTime,
						endTime:this.endTime,
						listState:index,
						OrderFrom:this.OrderFrom
					}
				});
				//跳转明细退款
			},
			sortChange(column, prop, order) {
				if (column.prop == 'SellMoney') {
					this.OrderByTpye = 0
				}
				if (column.prop == 'RealRefundMoney') {
					this.OrderByTpye = 1
				}
				if (column.prop == 'RecommendSellMoney') {
					this.OrderByTpye = 2
				}
				if (column.prop == 'RecommendRealRefundMoney') {
					this.OrderByTpye = 3
				}
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				this.getList();
			},
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWordsType: this.keyOptionsValue,
						KeyWords: this.searchKey,
						StartTime: this.starTime,
						EndTime: this.endTime,
						MemberDistibutorRoleId: this.grade,
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
						OrderByTpye: this.OrderByTpye,
						IsAsc: this.IsAsc,
						OrderFrom:this.OrderFrom,
					}
					let result = await memberDistributorSellInfo(data)
					this.tableData = result.Result.Results
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.loading = false
				}
			},
			// 导出
			async exportFun() {
				try {
					this.loading = true;
					if (!this.starTime || (!this.endTime)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '最多可查询31天内的订单，请重新选择时间!'
						});
						return
					}
					let url = this.exportUrl + '/pc/member/memberDistributorSellInfoExport?' +
						'KeyWordsType=' + this.keyOptionsValue + 
						'&KeyWords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&MemberDistibutorRoleId=' + (this.grade ? this.grade : '') +
						'&OrderByTpye=' + this.OrderByTpye +
						'&IsAsc=' + this.IsAsc +
						'&OrderFrom=' + this.OrderFrom 


					console.log(url, '导出的地址')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			getDaysBetween(dateString1, dateString2) {
				var startDate = Date.parse(dateString1);
				var endDate = Date.parse(dateString2);
				var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
				this.duringdays = Math.round(days)
			},
			handleFilter() {
				this.getDaysBetween(this.starTime,this.endTime)
				if (!this.starTime || (!this.endTime) || (this.duringdays > 31)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多可查询31天内的订单，请重新选择时间!'
					});
					return
				}
				
				this.currentPage = 1
				this.getList();
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			getRecentDays(days) {
				let time = new Date()
				let lastDay = new Date(time - 3600 * 24 * 1000)
				let firstDay = new Date(time - 3600 * 24 * 1000 * days)
				this.endTime = this.formatDate(lastDay) + ' ' + '23:59:59'
				this.starTime = this.formatDate(firstDay) + ' ' + '00:00:00'
			},
			formatTen(num) {
				return num > 9 ? (num + "") : ("0" + num);
			},
			//格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
			formatDate(date) {
				date = new Date(date)
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
			},
		}
	}
</script>
<style lang="less" scoped>
	.salesOverviewbox {
		background: #fff;
		padding: 10px;

		.oneFlows {
			width: 100px;
			margin-right: 5px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;

		}
	}
</style>
